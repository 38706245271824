<script>
import Emitter from "@/configs/emitter";

export default {
    name: "TurnstileCaptcha",
    props: {
        siteKey: {
            type: String,
            default: process.env.VUE_APP_TURNSTILE_SITE_KEY,
        },
        theme: {
            type: String,
            required: false,
            default: "auto",
        },
        size: {
            type: String,
            required: false,
            default: "flexible",
        },
        autoReset: {
            type: Boolean,
            required: false,
            default: false,
        },
        resetTimeout: {
            type: Number,
            required: false,
            default: 295 * 1000,
        },
        recaptchaCompat: {
            type: Boolean,
            required: false,
            default: false,
        },
        explicitRender: {
            type: Boolean,
            required: false,
            default: false,
        },
        appearance: {
            type: String,
            required: false,
            default: "always",
        },
        language: {
            type: String,
            required: false,
            default: "auto",
        },
    },
    data() {
        return {
            widgetId: null,
        };
    },
    methods: {
        initTurnstile() {
            const script = document.createElement("script");
            const turnstileSrc =
                "https://challenges.cloudflare.com/turnstile/v0/api.js";
            const callback = "onloadTurnstileCallback";
            const compat = this.recaptchaCompat ? "&compat=recaptcha" : "";
            const render = this.explicitRender ? "&render=explicit" : "";

            script.src = `${turnstileSrc}?onload=${callback}${compat}${render}`;
            script.async = true;
            script.defer = true;

            document.head.appendChild(script);
        },
        reset() {
            if (window.turnstile) {
                window.turnstile.reset(this.widgetId);
            }
        },
        remove() {
            if (this.widgetId) {
                window.turnstile.remove(this.widgetId);

                this.widgetId = null;
            }
        },
        async execute() {
            return new Promise((resolve, reject) => {
                try {
                    const verificationHandler = (token) => {
                        Emitter.off("verified", verificationHandler);

                        resolve(token);
                    };

                    Emitter.on("verified", verificationHandler);

                    this.render();
                } catch (error) {
                    reject(error);
                }
            });
        },
        render() {
            this.widgetId = window.turnstile.render("#example-container", {
                sitekey: this.siteKey,
                theme: this.theme,
                size: this.size,
                appearance: this.appearance,
                language: this.language,
                callback: (token) => {
                    this.$emit("verified", token);

                    //   this.remove();

                    if (this.autoReset) {
                        setTimeout(() => {
                           this.reset();
                        }, this.resetTimeout);
                    }
                },
                "expired-callback": () => {
                    this.reset();
                },
                "error-callback": (error) => {
                    console.error(`Error callback: ${error}`);

                    this.$emit("error", error);
                },
            });

            this.$emit("rendered");
        },
        onloadTurnstileCallback() {
            window.onloadTurnstileCallback = () => {
                this.render();
            };
        },
    },
    beforeMount() {
        if (typeof window.turnstile === "undefined" || !window.turnstile) {
            this.initTurnstile();
        }
    },
    mounted() {
        this.$emit("rendering");

        setTimeout(() => {            
            if (typeof window.turnstile === "undefined" || !window.turnstile) {
                this.onloadTurnstileCallback();
            } else {
                this.render();
            }
        }, 500);

    },
    beforeDestroy() {
        this.remove();
    },
};
</script>

<template>
  <div id="example-container">
    <slot />
  </div>
</template>
