<template>
    <div>
        <ul v-if="template === 'sleeve'"
            class="splash-links text-caption justify-center flex-wrap">
            <li v-for="(link,index) in staticLinks" :key="index">
                <router-link :to="link.to">{{ link.name }}</router-link>
            </li>
            <li>
                <CCBillComplaintFormLink />
            </li>
        </ul>
        <ul v-if="template === 'spigot'"
            class="splash-links text-mini justify-center flex-wrap">
            <li v-for="(link,index) in staticLinks" :key="index">
                <router-link :to="link.to">{{ link.name }}</router-link>
            </li>
            <li>
                <CCBillComplaintFormLink />
            </li>
        </ul>
        <ul v-if="template === 'simple'"
            class="splash-links">
            <li v-for="(link,index) in staticLinks" :key="index">
                <router-link :to="link.to">{{ link.name }}</router-link>
            </li>
            <li>
                <CCBillComplaintFormLink />
            </li>
        </ul>
    </div>
</template>

<script>
import CCBillComplaintFormLink from "@/components/app/common/CCBillComplaintFormLink";

export default {
    props: {
        template: {
            type: String,
            default: 'simple',
        }
    },
    name: "StaticPageLinks",
    data() {
        return {
            staticLinks: [
                {
                    to: '/faq',
                    name: 'FAQs',
                },
                {
                    to: '/terms',
                    name: 'Terms',
                },
                {
                    to: '/privacy-policy',
                    name: 'Privacy',
                },
                {
                    to: '/contact',
                    name: 'Contact',
                },
                {
                    to: {name: 'anti_fraud_policy'},
                    name: 'AML & Anti-Fraud',
                },
                {
                    to: {name: 'standard-agreement-noodzly-noodzler'},
                    name: 'Agreement',
                },
                {
                    to: {name: 'anti-slavery-trafficking'},
                    name: `Anti-Slavery`,
                },
                {
                    to: {name: 'dmca-takedown-policy'},
                    name: 'DMCA',
                },
            ],
        }
    },
    components: {
        CCBillComplaintFormLink,
    },
}
</script>

<style lang="scss">
@import '../../../sass/vuetify/variables';

.splash-links {
    list-style-type: none;
    padding: 0 !important;
    display: flex;

    li {
        &:not(:first-child) {
            margin-left: .5em;

            &::before {
                content: '•';
                display: inline-block;
                margin-right: .5em;
            }
        }

        a {
            text-decoration: none;
            color: $text-base;
        }
    }
}

@media only screen and (max-width: 767px) {
    .splash-links li { font-size: .7em }   
}
</style>
