<template>
    <div class="fill-height">
        <v-container>
            <auth-header title="Sign Up" />
            <v-form ref="form" class="auth-form mt-8">
                <v-text-field
                    solo
                    :background-color="background"
                    label="Username"
                    :error-messages="errors.name"
                    v-model="name"
                    :rules="nameRules"
                />
                <v-text-field
                    solo
                    :background-color="background"
                    label="Email"
                    :error-messages="errors.email"
                    v-model="email"
                    :rules="emailRules"
                />
                <v-text-field
                    solo
                    :background-color="background"
                    label="Password"
                    type="password"
                    :error-messages="errors.password"
                    v-model="password"
                    :rules="passwordRules"
                />
                <v-text-field
                    solo
                    :background-color="background"
                    label="Verify Password"
                    type="password"
                    v-model="password_confirmation"
                    v-on:keyup.enter="handleSignUp"
                    :rules="confirmPasswordRules.concat(passwordConfirmationRule)"
                />
                <v-text-field
                    solo
                    :background-color="background"
                    label="Referral Code (Optional)"
                    v-model="referral_code"
                    v-on:keyup.enter="handleSignUp"
                />
                <turnstile-captcha explicit-render @verified="captchaToken = $event" />
                <span v-if="errors.captcha_token" class="red--text"><strong>* </strong>
                    {{ errors.captcha_token[0] }}
                </span>

                <btn-large
                    class="btn-accent mt-2"
                    @click="handleSignUp"
                    text="Sign Up"
                />
                <div class="text-caption text-center mt-8">
                    <span>Already have an account?&nbsp;</span>
                    <router-link to="/login" class="link error--text">
                        <span>Login</span>
                    </router-link>
                </div>
            </v-form>
        </v-container>
    </div>
</template>

<script>
import AuthHeader from "@/components/app/auth/AuthHeader";
import TextField from "@/components/app/form/TextField";
import { mapActions } from "vuex";
import SocialLinks from "@/components/app/auth/SocialLinks";
import StaticPageLinks from "@/components/app/common/StaticPageLinks";
import { getReferralCode } from "@/helpers/referral";
import router from "@/router";
import TurnstileCaptcha from '@/components/app/auth/TurnstileCaptcha';

export default {
    name: "SignUp",
    components: {
        SocialLinks,
        StaticPageLinks,
        AuthHeader,
        TextField,
        TurnstileCaptcha,
    },
    data: () => ({
        errors: {},
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        referral_code: "",
        emailRules: [
            (v) => !!v || 'The email field is required',
            v => /.+@.+/.test(v) || "E-mail must be valid"
        ],
        nameRules: [
            (v) => !!v || 'The name field is required',
        ],
        passwordRules: [
            (v) => !!v || 'The password field is required'
        ],
        confirmPasswordRules: [v => !!v || "Password is required"],
        background: 'rgba(237,237,237, .1)',
        captchaToken: null,
        skipCaptchaOnLogin: true,
    }),
    computed: {
        passwordConfirmationRule() {
            return () =>
                this.password === this.password_confirmation || "Password must match";
            }
    },
    methods: {
        ...mapActions(["signUp", "getOwnMainUserData", "getCurrentUserData"]),
        handleSignUp() {
            if (!this.$refs.form.validate()) return;

            if (!this.captchaToken) {
                alert("Please complete the CAPTCHA");
                return;
            }
            const {
                email,
                name,
                password,
                password_confirmation,
                referral_code,
                captchaToken,
                skipCaptchaOnLogin
            } = this;
            this.$auth
                .register({
                    data: {
                        email,
                        name,
                        password,
                        password_confirmation,
                        referral_code,
                        captchaToken,
                        skipCaptchaOnLogin,
                        utm_source: router.currentRoute.query.utm_source,
                        utm_term: router.currentRoute.query.utm_term,
                        utm_campaign: router.currentRoute.query.utm_campaign,
                        utm_medium: router.currentRoute.query.utm_medium,
                        utm_content: router.currentRoute.query.utm_content,
                    },
                    remember: "Rob",
                    staySignedIn: true,
                    autoLogin: true,
                    fetchUser: true,
                })
                .then(() => {
                    localStorage.setItem("email_verified", false);
                    this.$router
                        .push({ name: "email-verify" })
                        .catch((e) => console.log(e));
                })
                .catch((e) => {
                    console.log(e.response);
                    this.errors = e.response.data.errors;
                });
        },
    },
    mounted() {
        this.referral_code = getReferralCode();
    },
};
</script>
